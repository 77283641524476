import { Section, SectionContainer } from "@/components/Sections";
import { CompetenceBaseTreeEx } from "@/server/backend-api";
import Button from "@/components/Button";
import SvgIconLogoSdgWheel from "@/components/Icons/generated/IconLogoSdgWheel";
import Link from "@/components/Link";
import {
  SecondLevelCompetenceNameMap,
  getTopicsWithAllSubtopics,
  getSecondLevelCompetence,
} from "@/utils/competenceTree";

type Props = {
  competenceTree: CompetenceBaseTreeEx;
};

export const StartPageTopicSection = (props: Props) => {
  const { competenceTree } = props;
  const competences = getTopicsWithAllSubtopics(competenceTree);
  const featuredTopics = {
    circularEconomy: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.circularEconomy,
      competences
    ),
    energyAndSustainability: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.energyAndSustainability,
      competences
    ),
    resilientCommunities: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.resilientCommunities,
      competences
    ),
    transportAndMobility: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.transportAndMobility,
      competences
    ),
    entrepreneurship: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.entrepreneurship,
      competences
    ),
    languageLearning: getSecondLevelCompetence(
      SecondLevelCompetenceNameMap.languageLearning,
      competences
    ),
  };

  return (
    <Section color="blue">
      <SectionContainer grid gridNoYGap className="gap-y-xl">
        <div className="col-span-full tablet-lg:col-span-4">
          <SvgIconLogoSdgWheel className="h-[180px] tablet:h-[280px] w-full" />
        </div>
        <div className="col-span-full tablet-lg:col-span-8">
          <h2 className="text-style-headline2 mb-sm">ECIU core topics</h2>
          {/*
           * We need to handle the case of missing topics.
           * Because during build in pipeline no topics are available but we still need to render the start page.
           */}
          <p className="text-style-body1 mb-md">
            ECIU University picks challenges that the communities and
            organisations face at this very moment. ECIU divided SDG
            into 4 main topics:{" "}
            <Link
              href={featuredTopics.circularEconomy?.url ?? "/missing-topic"}
              className="font-bold"
            >
              Circular economy
            </Link>
            ,{" "}
            <Link
              href={
                featuredTopics.energyAndSustainability?.url ?? "/missing-topic"
              }
              className="font-bold"
            >
              Energy and sustainability
            </Link>
            ,{" "}
            <Link
              href={
                featuredTopics.resilientCommunities?.url ?? "/missing-topic"
              }
              className="font-bold"
            >
              Resilient communities
            </Link>
            , and{" "}
            <Link
              href={
                featuredTopics.transportAndMobility?.url ?? "/missing-topic"
              }
              className="font-bold"
            >
              Transport and mobility.
            </Link>
          </p>
          <p className="text-style-body1 mb-lg">
            In addition to the SDG topics, we also focus on{" "}
            <Link
              href={featuredTopics.entrepreneurship?.url ?? "/missing-topic"}
              className="font-bold"
            >
              Entrepreneurship, technology and innovation
            </Link>{" "}
            as well as{" "}
            <Link
              href={competenceTree.transversal.url ?? "/missing-topic"}
              className="font-bold"
            >
              Transversal competencies
            </Link>{" "}
            and{" "}
            <Link
              href={featuredTopics.languageLearning?.url ?? "/missing-topic"}
              className="font-bold"
            >
              Language learning.
            </Link>
          </p>
          <Button kind="primary" href="/about-why-how#how">
            How it works
          </Button>
        </div>
      </SectionContainer>
    </Section>
  );
};
